import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { customPlaceholder } from '@/components/CustomPlaceholder/CustomPlceholder';

function PlaceholderForSearchList() {
  return (
    <List>
      <Row>
        <Post>
          <Left>
            <Image />
          </Left>
          <Right>
            <Text />
            <Text />
            <Footer />
          </Right>
        </Post>
      </Row>
    </List>
  );
}

const List = styled.li`
  margin-bottom: 75px;
  ${media.mobile(css`
    display: block;
  `)}
`;
const Row = styled.div`
  margin-top: 75px;
  &:first-child {
    margin-top: 0;
  }

  ${media.tabletSmallOnly(css`
    margin-top: 50px;
  `)}

  ${media.mobile(css`
    margin-top: 35px;
    padding-bottom: 14px;
    border-bottom: solid 0.01px;
  `)}
`;

const Post = styled.div`
  display: flex;
  ${media.mobile(css`
    display: block;
  `)}
`;

const Right = styled.div`
  display: flex;
  flex: 1px;
  max-width: 600px;
  flex-direction: column;
  overflow: hidden;
  ${media.mobile(css`
    margin-top: 9px;
    max-width: 100%;
  `)}
`;

const Text = styled.div`
  height: 17px;
  margin-bottom: 10px;
  ${customPlaceholder}
  ${media.mobile(css`
    max-width: 100%;
  `)}
`;
const Footer = styled.div`
  margin-top: auto;
  display: flex;
  height: 17px;
  ${customPlaceholder}
`;
const Left = styled.div`
  margin: 4px 30px 0 0;
  height: auto;

  ${media.mobile(css`
    margin: 4px 0 10px 0;
  `)}
`;

const Image = styled.div`
   min-height: 175px;
   width: 273px;
   ${customPlaceholder}
   ${media.tabletLargeOnly(css``)}
   ${media.tabletSmallOnly(css``)}
   ${media.mobile(css`
     width: 100%;
     max-width: 450px;
     margin: 0 auto;
     height: auto;
   `)}
    ${media.mobileMedium(css``)}
`;
export default PlaceholderForSearchList;
