import { FunctionComponent } from 'react';

import { CustomApp_PageContext } from '@/typings/hocs';
import { ErrorCode } from '@/typings/model';

export type PageModuleType = {
  component: FunctionComponent;
  getInitialProps?: (context: CustomApp_PageContext) => Promise<unknown>;
  template: string;
};

export type LangType = 'ru' | 'en';

export type PostBookmark = { id: number; alias: string };

export function getErrorLabel(values: ErrorCode | string) {
  switch (values) {
    case 'Phone is invalid':
      return 'phoneInvalid';
    case 'email already used':
      return 'emailUsed';
    case 'Пароль должен иметь длину от 8 до 100 и содержать не менее 1 заглавной буквы, 1 цифры и 1 строчной буквы':
      return 'littlePassword';
    case 'Неверный код или сессия':
      return 'codeInvalid';
    case 'Неверный логин или пароль':
      return 'loginInvalid';

    // case 'Invalid Password':
    //   return 'Пароль введён неверно';
    // case 'Min 6':
    //   return 'Минимум 6 символов ';
    // case 'CaseDiff':
    //   return 'Пароль должен содержать буквы верхнего и нижнего регистров';
    // case 'Symbols':
    //   return 'Пароль должен содержать символы';
    // case 'Numbers':
    //   return 'Пароль должен содержать цифры';

    default:
      return 'Произошла ошибка';
  }
}
