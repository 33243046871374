import React, { useState } from 'react';
import {
  Formik,
  FormikErrors,
  FormikHelpers,
  Form,
  useFormikContext,
} from 'formik';

import { sendRestoreLink } from '@services/requests';
import { useTranslation } from '@components/I18nProvider';
import { validators } from '@tager/web-core';

import { AuthPageType } from '@/components/modals/AuthModal/AuthModal.types';
import { TextInputFormik } from '@/components/TextInput';

import * as S from '../AuthModal.style';
type EmailFormValues = {
  email: string;
};
type Props = {
  setAuthPageType: (value: AuthPageType) => void;
  closeModal: () => void;
};

function validate(values: EmailFormValues): FormikErrors<EmailFormValues> {
  const errors: FormikErrors<EmailFormValues> = {};

  if (validators.required(values.email)) {
    errors.email = 'emptyEmail';
  } else if (validators.email(values.email)) {
    errors.email = 'noValidEmail';
  }

  return errors;
}

const INITIAL_VALUES: EmailFormValues = {
  email: '',
};

function PasswordRecoveryFormContainer(props: Props) {
  const [isSuccess, setSuccess] = useState(false);

  function handleSubmit(
    values: EmailFormValues,
    formikHelpers: FormikHelpers<EmailFormValues>
  ) {
    sendRestoreLink(values.email)
      .then(() => setSuccess(true))

      .finally(() => formikHelpers.setSubmitting(false));
  }

  return (
    <Formik<EmailFormValues>
      initialValues={INITIAL_VALUES}
      onSubmit={handleSubmit}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <PasswordRecoveryForm {...props} isSuccess={isSuccess} />
    </Formik>
  );
}

function PasswordRecoveryForm({
  setAuthPageType,
  isSuccess,
}: Props & { isSuccess: boolean }) {
  const { isSubmitting } = useFormikContext<EmailFormValues>();
  const { t } = useTranslation();

  return (
    <Form autoComplete={'off'}>
      <S.FormContent>
        <S.InputBlock>
          <S.InputRow>
            <S.Label>{t('email')}</S.Label>
            <TextInputFormik name="email" type="email" />
          </S.InputRow>
        </S.InputBlock>
        {!isSuccess ? (
          <S.SignInButtonRow>
            <S.SignInButton
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              isSubmitting={isSubmitting}
            >
              {t('resetPassword')}
            </S.SignInButton>
            <S.ForgotPasswordButton
              type="button"
              onClick={() => setAuthPageType('login')}
            >
              {t('logIn')}
            </S.ForgotPasswordButton>
          </S.SignInButtonRow>
        ) : (
          <>
            <S.TextField>{t('restoreText')}</S.TextField>
            <S.SignInButtonRow>
              <S.SignInButton
                type="button"
                onClick={() => setAuthPageType('login')}
                disabled={isSubmitting}
                isSubmitting={isSubmitting}
              >
                {t('login')}
              </S.SignInButton>
            </S.SignInButtonRow>
          </>
        )}
      </S.FormContent>
    </Form>
  );
}

export default PasswordRecoveryFormContainer;
