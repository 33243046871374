import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  useFormikContext,
} from 'formik';
import { TextInputFormik } from '@components/TextInput';
import { sendVerify } from '@services/requests';
import { useTranslation } from '@components/I18nProvider';

import { validators } from '@tager/web-core';

import { AuthPageType } from '@/components/modals/AuthModal/AuthModal.types';
// import { signInThunk } from '@/store/reducers/auth';

import { getErrorLabel } from '@/typings/common';

import * as S from '../AuthModal.style';
type RegistrationFormValues = {
  code: string;
};

const INITIAL_VALUES: RegistrationFormValues = {
  code: '',
};

function validate(
  values: RegistrationFormValues
): FormikErrors<RegistrationFormValues> {
  const errors: FormikErrors<RegistrationFormValues> = {};

  if (validators.required(values.code)) {
    errors.code = 'emptyCode';
  }

  return errors;
}

type Props = {
  setAuthPageType: (value: AuthPageType) => void;
  closeModal: () => void;
  uuid: string;
};

function RegistrationVerifyPage(props: Props) {
  const [commonError, setCommonError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function handleSubmit(
    values: RegistrationFormValues,
    formikHelpers: FormikHelpers<RegistrationFormValues>
  ) {
    setCommonError('');

    sendVerify(values.code, props.uuid)
      .then(() => {
        props.setAuthPageType('registrationAccount');
      })
      .catch((error) => {
        // const textError = getErrorLabel(error.body.message);
        const textError = getErrorLabel(error.body.message);
        setCommonError(textError);
      })
      .finally(() => formikHelpers.setSubmitting(false));
  }
  return (
    <Formik<RegistrationFormValues>
      initialValues={INITIAL_VALUES}
      onSubmit={handleSubmit}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <RegistrationForm
        {...props}
        isLoading={isLoading}
        commonError={commonError}
        setCommonError={() => setCommonError('')}
      />
    </Formik>
  );
}

function RegistrationForm({
  commonError,
  setCommonError,
}: Props & {
  commonError: any;
  setCommonError: (value: string) => void;
  isLoading: boolean;
}) {
  const { isSubmitting } = useFormikContext<RegistrationFormValues>();
  const { t } = useTranslation();

  return (
    <Form noValidate autoComplete={'off'}>
      <S.FormContent>
        <S.InputBlock>
          <S.InputRow>
            <S.Label> {t('code')}</S.Label>
            <TextInputFormik name="code" type="code" />
          </S.InputRow>
        </S.InputBlock>
        {commonError ? (
          <S.FormErrorLabel>{t(commonError)}</S.FormErrorLabel>
        ) : null}
        <S.SignInButtonRow>
          <S.SignInButton
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            isSubmitting={isSubmitting}
            onClick={() => setCommonError('')}
          >
            {t('signUpButton')}
          </S.SignInButton>
        </S.SignInButtonRow>
      </S.FormContent>
    </Form>
  );
}

export default RegistrationVerifyPage;
