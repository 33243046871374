import React from 'react';
import styled, { css } from 'styled-components';

import { MenuItemType } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import FooterMenuLink from '@/components/Layout/components/Footer/components/FooterMenuLink';

type Props = {
  menu: MenuItemType;
};

function FooterMenu({ menu }: Props) {
  return (
    <Component>
      <Header>{menu.label}</Header>
      {menu.children && (
        <List>
          {menu.children.map((item) => (
            <ListItem key={item.label}>
              <FooterMenuLink
                to={item.link}
                label={item.label}
                isNewTab={item.isNewTab}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Component>
  );
}

const Component = styled.div``;

const Header = styled.span`
  font-weight: 700;
  font-size: 15px;
  display: block;
  opacity: 0.6;
  white-space: nowrap;

  ${media.tabletSmall(css`
    opacity: 1;
  `)}
`;

const List = styled.ul``;

const ListItem = styled.li`
  margin-top: 20px;
`;

export default FooterMenu;
