import styled, { css } from 'styled-components';
import { darken } from 'polished';

import Button from '@/components/Button';
import { media } from '@/utils/mixin';

export const Inner = styled.div`
  min-height: 300px;
  padding: 50px;
  position: relative;
  ${media.mobile(css`
    padding: 30px 15px;
    overflow-y: scroll;
    min-height: 100%;
    height: 100%;
  `)}
`;
export const Heading = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: var(--popup-heading-bg);
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 3px;
  color: #999;
  padding: 7px 0;
`;

export const FormBlock = styled.div`
  width: 330px;
  form {
    input {
      border-radius: 2px;
    }
  }
  ${media.mobile(css`
    min-height: 100%;
    max-width: 100%;
    height: 100%;
    margin: 0 auto 50px;
    form {
      height: 80%;
    }
  `)}
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: -30px;
  fill: black;
  transition: 0.5s;
  svg {
    width: 16.26px;
    height: 16.26px;
    display: block;
    fill: var(--popup-close-color);
  }
  &:hover {
    opacity: 0.6;
  }
  ${media.mobile(css`
    top: 45px;
    right: 10px;
  `)}
`;

export const TitleBlock = styled.div`
  display: flex;
  margin-bottom: 22px;
  min-height: 42px;
  flex-shrink: 0;
  ${media.mobile(css`
    margin-bottom: 8px;
  `)}
`;

export const Title = styled.button<{ isActive: boolean }>`
  text-align: left;
  font-size: 32px;
  font-weight: 700;
  line-height: 35px;
  color: var(--tab-active);
  border-bottom: 2px solid transparent;
  margin-right: 25px;
  padding-bottom: 8px;
  ${(props) =>
    props.isActive
      ? css`
          color: var(--tab);
          border-bottom-color: var(--tab);
          cursor: initial;
        `
      : ''};
  ${media.mobile(css`
    padding-bottom: 0;
  `)}
  ${media.mobileMedium(css`
    font-size: 24px;
    line-height: 30px;
  `)}
`;

export const RecoveryTitle = styled.span`
  text-align: left;
  font-size: 32px;
  font-weight: 700;
  line-height: 35px;
  color: var(--main-text-color);
  border-bottom: 2px solid transparent;
  margin-right: 25px;
  padding-bottom: 8px;

  ${media.mobile(css`
    padding-bottom: 0;
  `)}
  ${media.mobileMedium(css`
    font-size: 24px;
    line-height: 30px;
  `)}
`;

export const TextField = styled.p`
  display: block;
  background: #e6f4de;
  color: #43b200 !important;
  padding: 15px 40px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 15px;
`;

export const Warning = styled.p`
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 300;
  color: var(--main-text-color);
  opacity: 0.8;
  font-size: 15px;
  line-height: 20px;
`;
export const Agreement = styled.a`
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

export const Label = styled.label`
  position: absolute;
  cursor: text;
  z-index: 1;
  left: 15px;
  top: 14px;
  color: #999;
  font-size: 15px;
  line-height: normal;
  pointer-events: none;
`;

export const InputBlock = styled.div`
  margin-bottom: 20px;
  // ${media.mobile(css`
    //   margin-bottom: 115px;
    //
  `)}
`;

export const FormContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 230px;
`;

export const InputRow = styled.div`
  position: relative;
  div {
    width: 100%;
    input:-webkit-autofill {
      -webkit-box-shadow: inset 0 0 0 50px var(--form-input-background) !important;
      -webkit-text-fill-color: var(--form-input-color) !important;
    }
  }
  border: 1px solid var(--form-input-border);
  min-height: 50px;
  line-height: 50px;
  display: flex;
  cursor: pointer;
  background: var(--form-input-background);
  border-bottom-color: transparent;
  border-radius: 4px 4px 0 0;
  &:nth-child(2) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-color: var(--form-input-border);
  }
  // ${media.mobile(css`
    //   margin-bottom: 114px;
    //
  `)}
`;
export const EyeBlock = styled.button<{ isSeen: boolean }>`
  position: absolute;
  right: 0;
  width: 45px;
  height: 50px;
  svg {
    stroke: var(--main-text-color);
    fill: var(--main-text-color);
    transition: opacity 0.2s ease;
    opacity: 0.6;
    &:first-child {
      display: ${(props) => (props.isSeen ? 'inline-block' : 'none')};
    }
    &:last-child {
      display: ${(props) => (!props.isSeen ? 'inline-block' : 'none')};
    }
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

export const SignInButtonRow = styled.div`
  margin-top: auto;
`;

export const SignInButton = styled(Button)<{ isSubmitting: boolean }>`
  display: inline-block;
  position: relative;
  border: 0;
  transition: 0.3s;
  cursor: pointer;
  text-align: center;
  padding: 0 20px;
  margin-right: 30px;
  line-height: 40px;
  font-size: 15px;
  border-radius: 3px;
  font-weight: 600;
  background: var(--blue-color);
  text-transform: none;
  letter-spacing: normal;
  &:hover {
    background: ${darken(0.04, '#2f4efe')};
  }
  ${(props) =>
    props.isSubmitting
      ? css`
          color: var(--blue-color);
          &:hover {
            color: ${darken(0.04, '#2f4efe')};
          }
          &::before,
          ::after {
            content: '';
            position: absolute;
            margin: auto;
            top: -4px;
            left: 0;
            right: 0;
            display: block;
            box-sizing: border-box;
            width: 50px;
            height: 50px;
            border: 2px solid #fff;
            border-radius: 50%;
            opacity: 0;
            animation-name: intervalLoadingAnimation;
            animation-duration: 1.8s;
            animation-timing-function: ease-out;
            animation-iteration-count: infinite;
          }
        `
      : css`
          color: #fff;
        `}

  @keyframes intervalLoadingAnimation {
    0% {
      border-width: 10px;
      transform: scale(0.1);
      opacity: 1;
    }

    100% {
      border-width: 2px;
      transform: scale(1);
      opacity: 0;
    }
  }
  ${media.mobile(css`
    margin-right: 20px;
  `)}
`;

export const SuccessTextContainer = styled.div`
  margin-bottom: 15px;
`;

export const ForgotPasswordButton = styled.button`
  color: #999;
  font-size: 15px;
  &:hover {
    text-decoration: underline;
  }
`;

export const FormErrorLabel = styled.span`
  background: #fff4e9;
  padding: 0 15px;
  min-height: 48px;
  max-height: 65px;
  color: #ff7e00;
  border: 1px solid #ffdfc0;
  line-height: 18px;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
