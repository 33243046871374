import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useMedia, scroller } from '@tager/web-core';
import { createMediaQuery } from '@tager/web-components';

import { breakpoints, fonts } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { ReactComponent as SearchSvg } from '@/assets/svg/search.svg';
import { ReactComponent as BurgerSvg } from '@/assets/svg/burger.svg';
import { withLang } from '@/utils/common';
import { useTranslation } from '@/components/I18nProvider';

import SearchScreen from './components/SearchScreen';
import Logo from './components/Logo';
import MainMenu from './components/MainMenu/MainMenu';
import LanguageSelect from './components/LanguageSelect';
import Dropdown from './components/Dropdown';
import MobileSidebar from './components/MobileSidebar';

function Header() {
  const { language } = useTranslation();

  const headerMenuItemList = useTypedSelector((state) =>
    selectMenuItemListByAlias(state, withLang(`header_menu`, language))
  );

  const dropMenuItemList = useTypedSelector((state) =>
    selectMenuItemListByAlias(state, withLang(`dropdown_menu`, language))
  );

  const [isBurgerDropdownVisible, setBurgerDropdownVisible] = useState(false);
  const [isSearchScreenVisible, setSearchScreenVisible] = useState(false);

  const isMobile = useMedia(
    createMediaQuery({
      max: breakpoints.tabletSmall,
    })
  );

  return (
    <HeaderContainer>
      <HeaderInner>
        <HeaderLeft>
          <Logo />
        </HeaderLeft>

        <HeaderMenu>
          {headerMenuItemList ? (
            <MainMenu menuList={headerMenuItemList} />
          ) : null}
        </HeaderMenu>

        <HeaderActions>
          <HeaderActionsItem>
            <HeaderLanguage>
              <LanguageSelect />
            </HeaderLanguage>
          </HeaderActionsItem>
          <HeaderActionsItem>
            <SearchButton
              onClick={() => {
                setSearchScreenVisible(true);
                scroller.lock();
              }}
            >
              <SearchSvg />
            </SearchButton>
            {isSearchScreenVisible ? (
              <SearchScreen
                onClose={() => {
                  setSearchScreenVisible(false);
                  scroller.unlock();
                }}
              />
            ) : null}
          </HeaderActionsItem>
          <HeaderActionsItem>
            <BurgerMenuWrap>
              <BurgerButton
                onClick={() => {
                  setBurgerDropdownVisible(true);
                  if (isMobile) {
                    scroller.lock();
                  }
                }}
              >
                <BurgerSvg />
              </BurgerButton>
              {!isMobile && isBurgerDropdownVisible && (
                <Dropdown
                  menu={dropMenuItemList ? dropMenuItemList : []}
                  onClose={() => setBurgerDropdownVisible(false)}
                />
              )}
            </BurgerMenuWrap>
          </HeaderActionsItem>
        </HeaderActions>

        {isMobile && isBurgerDropdownVisible && (
          <MobileSidebar
            menu={dropMenuItemList ? dropMenuItemList : []}
            onClose={() => {
              setBurgerDropdownVisible(false);
              scroller.unlock();
            }}
          />
        )}
      </HeaderInner>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.header`
  position: relative;
  margin-bottom: 30px;
  ${media.tabletSmallOnly(css`
    margin-bottom: 50px;
  `)}
  ${media.mobileLarge(css`
    margin-bottom: 55px;
  `)}
`;

const HeaderLeft = styled.div``;

const HeaderMenu = styled.div`
  padding: 0 70px;
  ${media.tabletLarge(css`
    padding: 0 70px;
  `)}
  ${media.tabletSmallOnly(css`
    position: absolute;
    bottom: -20px;
    left: 1px;
    right: 0;
    justify-content: left;
    padding: 0;
    overflow-y: hidden;
    overflow-x: scroll;
  `)}
  ${media.mobile(css`
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    justify-content: left;
    padding: 0 0 10px 27px;
    overflow-y: hidden;
    overflow-x: scroll;
    margin-left: -27px;
    margin-right: -16px;
  `)}
`;

const HeaderLanguage = styled.div``;

const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 29px 0;
  ${media.tabletSmall(css`
    padding: 10px 0 20px 1px;
  `)}
`;

const HeaderActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const HeaderActionsItem = styled.div`
  margin-left: 15px;
  &:first-child {
    margin-left: 0;
  }
  ${media.tabletSmallOnly(css`
    margin-left: 7px;
  `)}
`;

const SearchButton = styled.button`
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Open Sans', 'Arial', 'Helvetica', 'Tahoma', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  svg {
    width: 18px;
    height: 18px;
    fill: var(--main-text-color);
  }
  &:hover {
    opacity: 0.6;
  }
`;
const BurgerButton = styled.button`
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-family: ${fonts.Montserrat};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  position: relative;
  svg {
    width: 18px;
    height: 12px;
    fill: var(--main-text-color);
  }
  &:hover {
    opacity: 0.6;
  }
`;
const BurgerMenuWrap = styled.div`
  position: relative;
`;

export default Header;
