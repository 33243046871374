import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { useOnClickOutside } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { useTranslation } from '@/components/I18nProvider';

import LanguageSelectPopup from './LanguageSelectPopup';

function LanguageSelect() {
  const { language } = useTranslation();

  const [showSelect, setShowSelect] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(selectRef, () => setShowSelect(false));

  return (
    <Language ref={selectRef} onClick={() => setShowSelect(!showSelect)}>
      <LanguageValue>{language === 'ru' ? 'Рус' : 'Eng'}</LanguageValue>
      {showSelect && (
        <LanguagePopup>
          <LanguageSelectPopup onClose={() => setShowSelect(false)} />
        </LanguagePopup>
      )}
    </Language>
  );
}

const Language = styled.div`
  cursor: pointer;
  position: relative;
  ${media.mobileLarge(css`
    display: none;
  `)}
`;

const LanguagePopup = styled.div`
  position: absolute;
  z-index: 1;
  right: -10px;
  width: 322px;
  top: 100%;
  margin-top: 10px;
  background: var(--dropdown-bg-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  border-image: linear-gradient(0deg, rgba(0, 0, 0, 0.1), transparent) 1 100%;
`;

const LanguageValue = styled.div`
  padding-right: 12px;
  position: relative;
  text-transform: uppercase;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 3px solid #000;
    border-top-color: var(--main-text-color);
    z-index: 1;
    right: 0;
  }
  &:hover {
    opacity: 0.6;
  }
`;

export default LanguageSelect;
