import React from 'react';
import styled from 'styled-components';

import { MenuItemType } from '@tager/web-modules';

import { ReactComponent as LinkArrowSvg } from '@/assets/svg/Linkarrow.svg';

type Props = {
  menu: Array<MenuItemType>;
};

function DropdownMenu({ menu }: Props) {
  return (
    <DropDownList>
      {menu &&
        menu.map((item, index) => (
          <DropDownElem>
            <LinkItem href={item.link || ''} target="_blank">
              <DropDownLinkContent>{item.label}</DropDownLinkContent>
              <LinkArrowSvg />
            </LinkItem>
          </DropDownElem>
        ))}
    </DropDownList>
  );
}

const DropDownList = styled.ul``;

const DropDownElem = styled.li`
  margin: 0;
  line-height: 23px;
  &:first-child {
    margin-top: 0;
  }
`;
const LinkItem = styled.a`
  font-size: 15px;
  padding: 0 30px;
  line-height: 30px;
  align-items: center;
  transition: 0.2s;
  text-align: left;
  width: 100%;
  color: var(--main-text-color);
  display: flex;
  svg {
    width: 6px;
    height: 6px;
    fill: var(--main-text-color);
  }
  &:hover {
    background: var(--dropdown-bg-hover-color);
  }
`;
const DropDownLinkContent = styled.span`
  position: relative;
  margin-right: 4px;
`;

export default DropdownMenu;
