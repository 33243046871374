import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import AuthModal from '@components/modals/AuthModal';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import { useModal } from '@components/Modal/Modal.hooks';
import { useTranslation } from '@components/I18nProvider';
import { MenuItemType } from '@tager/web-modules';
import { redirect, useOnClickOutside } from '@tager/web-core';

import { ReactComponent as CloseSvg } from '@/assets/svg/header-dropdown-close.svg';
import ThemeSelect from '@/components/Layout/components/Header/components/ThemeSelect';
import DropdownMenu from '@/components/Layout/components/Header/components/DropdownMenu';
import { useTypedSelector } from '@/store/store';
import {
  selectIsUserAuthorized,
  selectUserProfile,
  signOutThunk,
} from '@/store/reducers/auth';

import Bookmarks from './Bookmars';

type Props = {
  menu: Array<MenuItemType>;
  onClose: () => void;
};

function Dropdown({ menu, onClose }: Props) {
  const navRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(navRef, onClose);
  const openModal = useModal();
  const router = useRouter();
  const dispatch = useDispatch();
  const isUserAuthorized = useTypedSelector((state) =>
    selectIsUserAuthorized(state)
  );
  const { language, t } = useTranslation();

  const profile = useTypedSelector((state) => selectUserProfile(state));

  function handleLogin() {
    if (!isUserAuthorized) {
      openModal(AuthModal, {});
      onClose();
    }
  }
  function handleSignOut() {
    if (dispatch(signOutThunk())) {
      onClose();
      redirect({
        currentUrl: router.asPath ?? '',
        location: language === 'en' ? '/en' : '/',
      });
    }
  }

  return (
    <BurgerMenu ref={navRef}>
      <BtnClose onClick={() => onClose()}>
        <CloseSvg />
      </BtnClose>
      <ThemeSelect />
      {isUserAuthorized ? (
        <>
          <Title>{t('personalAccount')}</Title>
          <HeaderBlock>
            {profile ? (
              <ProfilePageLink>
                {profile.firstName} {profile.lastName}
              </ProfilePageLink>
            ) : null}
            <Bookmarks />
          </HeaderBlock>
        </>
      ) : null}
      <DropDownSection>
        <DropdownMenu menu={menu} />
      </DropDownSection>
      <LoginSection>
        {!isUserAuthorized ? (
          <HeaderButton
            isUserAuthorized={isUserAuthorized}
            onClick={() => handleLogin()}
          >
            {t('logIn')}
          </HeaderButton>
        ) : (
          <HeaderButton
            isUserAuthorized={isUserAuthorized}
            onClick={() => handleSignOut()}
          >
            {t('logout')}
          </HeaderButton>
        )}
      </LoginSection>
    </BurgerMenu>
  );
}

const BurgerMenu = styled.div`
  position: absolute;
  right: -10px;
  top: -15px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
  border-image: linear-gradient(0deg, rgba(0, 0, 0, 0.1), transparent) 1 40%;
  border-style: solid;
  border-width: 1px;
  width: 270px;
  border-radius: 2px;
  background: var(--dropdown-bg-color);
  z-index: 9;
`;

const BtnClose = styled.div`
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 8px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  svg {
    fill: var(--main-text-color);
  }
  &:hover {
    opacity: 0.6;
  }
`;

const Title = styled.div`
  padding: 20px 0 0 30px;
  font-size: 13px;
  color: #999;
  letter-spacing: 1.73px;
  text-transform: uppercase;
  line-height: 14px;
  display: block;
`;

const HeaderBlock = styled.div`
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--dropdown-border-color);
`;

const ProfilePageLink = styled.div`
  font-size: 15px;
  padding: 0 30px;
  line-height: 30px;
  align-items: center;
  text-align: left;
  width: 100%;
  display: flex;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    background: var(--dropdown-bg-hover-color);
  }
`;

const DropDownSection = styled.div`
  padding: 15px 0;
  background: var(--dropdown-bg-color);
  border-bottom: 1px solid var(--dropdown-border-color);
`;
const LoginSection = styled.div`
  padding: 15px 0;
`;

const HeaderButton = styled.button<{ isUserAuthorized: boolean }>`
  background: var(--dropdown-bg-Fcolor);
  color: ${(props) =>
    props.isUserAuthorized ? '#dc5959' : 'var(--main-text-color)'};
  font-size: 15px;
  padding: 4px 30px;
  margin: -7px 0;
  line-height: 30px;
  align-items: center;
  text-align: left;
  width: 100%;
  display: flex;
  transition: 0.2s;
  &:hover {
    background: var(--dropdown-bg-hover-color);
  }
  ${(props) =>
    props.isUserAuthorized
      ? css`
          &:hover {
            background: var(--dropdown-bg-hover-color);
            color: var(--main-text-color);
          }
        `
      : null};
`;

export default Dropdown;
