import React, { useState } from 'react';
import { ModalContainer } from '@components/Modal';
import RegistrationVerifyPage from '@components/modals/AuthModal/components/RegistrationVerifyPage';
import RegistrationSubmitPage from '@components/modals/AuthModal/components/RegistrationSubmitPage';

import { useTranslation } from '@components/I18nProvider';
import { ModalProps } from '@tager/web-components';

import { ReactComponent as CloseIcon } from '@/assets/svg/close.svg';

import RegistrationPage from './components/RegistrationPage';
import * as S from './AuthModal.style';
import { AuthPageType } from './AuthModal.types';
import LoginForm from './components/LoginForm';
import PasswordRecoveryForm from './components/PasswordRecoveryForm';

type Props = ModalProps;

function AuthModal({ closeModal }: Props) {
  const [authPageType, setAuthPageType] = useState<AuthPageType>('login');
  const [uuid, setUuid] = useState('');
  const { t } = useTranslation();

  return (
    <ModalContainer width={430} centered={true} padding={'0'}>
      <S.Inner>
        <S.CloseButton onClick={closeModal}>
          <CloseIcon />
        </S.CloseButton>
        <S.Heading>UNITED TRADERS ACCOUNT</S.Heading>
        <S.FormBlock>
          {authPageType !== 'password_recovery' ? (
            <>
              <S.TitleBlock>
                <S.Title
                  isActive={
                    'registration' === authPageType ||
                    'registrationCode' === authPageType ||
                    'registrationAccount' === authPageType
                  }
                  onClick={() => setAuthPageType('registration')}
                >
                  {t('signUp')}
                </S.Title>
                <S.Title
                  isActive={'login' === authPageType}
                  onClick={() => setAuthPageType('login')}
                >
                  {t('logIn')}
                </S.Title>
              </S.TitleBlock>
              {authPageType === 'registration' && (
                <RegistrationPage
                  setAuthPageType={setAuthPageType}
                  closeModal={closeModal}
                  setUuid={setUuid}
                />
              )}
              {authPageType === 'registrationCode' && (
                <RegistrationVerifyPage
                  setAuthPageType={setAuthPageType}
                  closeModal={closeModal}
                  uuid={uuid}
                />
              )}
              {authPageType === 'registrationAccount' && (
                <RegistrationSubmitPage
                  setAuthPageType={setAuthPageType}
                  closeModal={closeModal}
                  uuid={uuid}
                />
              )}
              {authPageType === 'login' && (
                <LoginForm
                  setAuthPageType={setAuthPageType}
                  closeModal={closeModal}
                />
              )}
            </>
          ) : (
            <>
              <S.TitleBlock>
                <S.RecoveryTitle>{t('restorePasswordButton')}</S.RecoveryTitle>
              </S.TitleBlock>
              <PasswordRecoveryForm
                closeModal={closeModal}
                setAuthPageType={setAuthPageType}
              />
            </>
          )}
        </S.FormBlock>
      </S.Inner>
    </ModalContainer>
  );
}

export default AuthModal;
