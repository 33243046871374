import React from 'react';
import styled, { css } from 'styled-components';

import Link from '@/components/Link';
import { ReactComponent as UtmediaLogo } from '@/assets/svg/logo.svg';
import { media } from '@/utils/mixin';
import { addSubpathToUrl } from '@/utils/common';
import { useTranslation } from '@/components/I18nProvider';

function Logo() {
  const { language } = useTranslation();
  return (
    <LogoWrap>
      <Link to={addSubpathToUrl('/', language)}>
        <UtmediaLogo />
      </Link>
    </LogoWrap>
  );
}

const LogoWrap = styled.div`
  display: block;
  flex-basis: 170px;
  min-width: 170px;
  svg {
    width: 170px;
    height: 28px;
    fill: var(--main-text-color);
  }
  ${media.mobileLarge(css`
    svg {
      transform: scale(0.75);
      margin-left: -20px;
    }
  `)}
  a {
    cursor: pointer;
  }
`;

export default Logo;
