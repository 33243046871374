import React from 'react';
import styled from 'styled-components';
import { SimplifiedLinkToType } from '@components/Link';

import { ReactComponent as LanguageRusSvg } from '@/assets/svg/rus.svg';
import { ReactComponent as ArrowSvg } from '@/assets/svg/arrow.svg';
import { ReactComponent as LanguageEnSvg } from '@/assets/svg/en.svg';
import { useTranslation } from '@/components/I18nProvider';
import { LangType } from '@/typings/common';
import Link from '@/components/Link';
import { addSubpathToUrl } from '@/utils/common';
type Props = {
  onClose: () => void;
};

type LangOption = {
  lang: LangType;
  icon: React.ReactNode;
  label: string;
  link: SimplifiedLinkToType;
};

const OPTION_LIST: Array<LangOption> = [
  {
    lang: 'ru',
    icon: <LanguageRusSvg />,
    label: 'Русский',
    link: { href: '/', as: addSubpathToUrl('/', 'ru') },
  },
  {
    lang: 'en',
    icon: <LanguageEnSvg />,
    label: 'English',
    link: { href: '/', as: addSubpathToUrl('/', 'en') },
  },
];

function LanguageSelectPopup(props: Props) {
  const { onClose } = props;

  const { language, changeLanguage } = useTranslation();

  function handleSelect(option: LangOption) {
    changeLanguage(option.lang);
    onClose();
  }

  return (
    <LanguagePopup>
      <LanguageList>
        {OPTION_LIST.map((option) => (
          <LanguageItem onClick={() => handleSelect(option)}>
            <LanguageLink to={option.link}>
              {option.icon}
              {option.label}
              {option.lang === language ? (
                <CheckedWrap>
                  <ArrowSvg />
                </CheckedWrap>
              ) : null}
            </LanguageLink>
          </LanguageItem>
        ))}
      </LanguageList>
    </LanguagePopup>
  );
}

const LanguageList = styled.ul`
  margin: 0;
  list-style-type: none;
`;

const LanguageItem = styled.li`
  margin-top: 11px;
  position: relative;
`;

const LanguageLink = styled(Link)`
  font-size: 15px;
  line-height: 43px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  padding-left: 68px;
  align-items: center;
  padding-right: 15px;
  &:hover {
    background: var(--dropdown-bg-hover-color);
  }
  svg {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translate(0%, -50%);
  }
  &:first-child {
    margin-top: 12px;
  }
  &:last-child {
    margin-bottom: 11px;
  }
`;

const LanguagePopup = styled.div`
  z-index: 1;
`;

const CheckedWrap = styled.div`
  max-width: 17px;
  position: relative;
  right: 15px;
  top: 0;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  svg {
    width: 17px;
    height: 12px;
    fill: var(--main-text-color);
  }
`;

export default LanguageSelectPopup;
