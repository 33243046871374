import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';

import { CustomLinkProps } from '@tager/web-components';

import Link from '@/components/Link';
import { useTranslation } from '@/components/I18nProvider';
import { StoreDispatch, useTypedSelector } from '@/store/store';
import { selectBookmarksResource } from '@/store/reducers/pages/bookmarks';
import { selectIsUserAuthorized } from '@/store/reducers/auth';

type Props = {
  onClose?: () => void;
};

function Bookmarks({ onClose }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch<StoreDispatch>();
  const isUserAuthorized = useTypedSelector((state) =>
    selectIsUserAuthorized(state)
  );
  const bookmarks = useTypedSelector((state) => selectBookmarksResource(state))
    .data;

  useEffect(() => {
    if (isUserAuthorized) {
    }
  }, [dispatch, isUserAuthorized]);

  return (
    <BookmarksBlock onClick={onClose}>
      <Link as={BookmarkPageLink} to={'/profile/bookmarks'}>
        {t('saved')}{' '}
        <NumBookmarks>
          {bookmarks.length !== 0 ? bookmarks.length : 0}
        </NumBookmarks>
      </Link>
    </BookmarksBlock>
  );
}

const BookmarksBlock = styled.div``;
const BookmarkPageLink = styled.a<CustomLinkProps>`
  font-size: 15px;
  padding: 0 30px;
  line-height: 30px;
  align-items: center;
  text-align: left;
  width: 100%;
  display: flex;
  transition: 0.2s;
  &:hover {
    background: var(--dropdown-bg-hover-color);
  }
  ${(props) =>
    props.isActive &&
    css`
      cursor: pointer;
    `}
`;

const NumBookmarks = styled.span`
  background: rgba(23, 108, 212, 0.1);
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: #176cd4;
  letter-spacing: 0;
  width: 23px;
  height: 23px;
  border-radius: 100%;
  display: inline-block;
  margin-left: 8px;
  text-align: center;
  line-height: 25px;
`;

export default Bookmarks;
