import React, { useState } from 'react';
import {
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  useFormikContext,
} from 'formik';
import { TextInputFormik } from '@components/TextInput';
import { sendRegisterLink } from '@services/requests';

import { useTranslation } from '@components/I18nProvider';
import { validators } from '@tager/web-core';

import { AuthPageType } from '@/components/modals/AuthModal/AuthModal.types';
import { getErrorLabel } from '@/typings/common';

import * as S from '../AuthModal.style';
type RegistrationFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
};

const INITIAL_VALUES: RegistrationFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
};

function validate(
  values: RegistrationFormValues
): FormikErrors<RegistrationFormValues> {
  const errors: FormikErrors<RegistrationFormValues> = {};

  if (validators.required(values.email)) {
    errors.email = 'emptyEmail';
  } else if (validators.email(values.email)) {
    errors.email = 'noValidEmail';
  }

  if (validators.required(values.firstName)) {
    errors.firstName = 'emptyName';
  }
  if (validators.required(values.lastName)) {
    errors.lastName = 'emptySurname';
  }
  if (validators.required(values.phone)) {
    errors.phone = 'emptyPhone';
  }

  return errors;
}

type Props = {
  setAuthPageType: (value: AuthPageType) => void;
  closeModal: () => void;
  setUuid: (value: string) => void;
};

function RegistrationPage(props: Props) {
  const [commonError, setCommonError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function handleSubmit(
    values: RegistrationFormValues,
    formikHelpers: FormikHelpers<RegistrationFormValues>
  ) {
    setCommonError('');
    sendRegisterLink(values)
      .then((response) => {
        props.setAuthPageType('registrationCode');
        props.setUuid(response.data.uuid);
      })
      .catch((error) => {
        const textError = getErrorLabel(error.body.message);
        setCommonError(textError);
      })
      .finally(() => formikHelpers.setSubmitting(false));
  }
  return (
    <Formik<RegistrationFormValues>
      initialValues={INITIAL_VALUES}
      onSubmit={handleSubmit}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <RegistrationForm
        {...props}
        isLoading={isLoading}
        commonError={commonError}
        setCommonError={() => setCommonError('')}
      />
    </Formik>
  );
}

function RegistrationForm({
  commonError,
  setCommonError,
}: Props & {
  commonError: any;
  setCommonError: (value: string) => void;
  isLoading: boolean;
}) {
  const { isSubmitting } = useFormikContext<RegistrationFormValues>();
  const { t } = useTranslation();

  return (
    <Form noValidate autoComplete={'off'}>
      <S.FormContent>
        <S.InputBlock>
          <S.InputRow>
            <S.Label>{t('name')}</S.Label>
            <TextInputFormik name="firstName" type="firstName" />
          </S.InputRow>
          <S.InputRow>
            <S.Label>{t('surname')}</S.Label>
            <TextInputFormik name="lastName" type="lastName" />
          </S.InputRow>
        </S.InputBlock>

        <S.InputBlock>
          <S.InputRow>
            <S.Label>{t('email')}</S.Label>
            <TextInputFormik name="email" type="email" />
          </S.InputRow>
          <S.InputRow>
            <S.Label>{t('phone')}</S.Label>
            <TextInputFormik name="phone" type="phone" />
          </S.InputRow>
        </S.InputBlock>
        {commonError ? (
          <S.FormErrorLabel>{t(commonError)}</S.FormErrorLabel>
        ) : null}
        <S.Warning>
          {t('clickReg')}{' '}
          <S.Agreement href="#">{t('conditionsUser')}</S.Agreement>
        </S.Warning>
        <S.SignInButtonRow>
          <S.SignInButton
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            isSubmitting={isSubmitting}
            onClick={() => setCommonError('')}
          >
            {t('signUpButton')}
          </S.SignInButton>
        </S.SignInButtonRow>
      </S.FormContent>
    </Form>
  );
}

export default RegistrationPage;
