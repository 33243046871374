import React, { useState } from 'react';
import {
  Form,
  Formik,
  useFormikContext,
  FormikHelpers,
  FormikErrors,
} from 'formik';
import { useDispatch } from 'react-redux';

import { useTranslation } from '@components/I18nProvider';
import { validators } from '@tager/web-core';

import { TextInputFormik } from '@/components/TextInput';
import { StoreDispatch } from '@/store/store';
import { ReactComponent as EyeSvg } from '@/assets/svg/eye.svg';
import { ReactComponent as EyeCloseSvg } from '@/assets/svg/eyeClose.svg';
import { signInThunk } from '@/store/reducers/auth';

import * as S from '../AuthModal.style';
import { AuthPageType } from '../AuthModal.types';

type LoginFormValues = {
  login: string;
  password: string;
};

function validate(values: LoginFormValues): FormikErrors<LoginFormValues> {
  const errors: FormikErrors<LoginFormValues> = {};

  if (validators.required(values.login)) {
    errors.login = 'emptyLoginEmail';
  }

  if (validators.required(values.password)) {
    errors.password = 'emptyPassword';
  }
  console.log(Object.keys(errors).length);
  return errors;
}

const INITIAL_VALUES: LoginFormValues = {
  login: '',
  password: '',
};

type Props = {
  setAuthPageType: (value: AuthPageType) => void;
  closeModal: () => void;
};

function LoginFormContainer(props: Props) {
  const dispatch = useDispatch<StoreDispatch>();

  const [commonError, setCommonError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { closeModal } = props;
  function handleSubmit(
    values: LoginFormValues,
    formikHelpers: FormikHelpers<LoginFormValues>
  ) {
    setCommonError('');
    dispatch(signInThunk(values))
      .then(() => closeModal())
      .catch((error) => {
        setCommonError(error.body.message);
      })
      .finally(() => formikHelpers.setSubmitting(false));
  }

  return (
    <Formik<LoginFormValues>
      initialValues={INITIAL_VALUES}
      onSubmit={handleSubmit}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <LoginForm
        {...props}
        isLoading={isLoading}
        commonError={commonError}
        setCommonError={() => setCommonError('')}
      />
    </Formik>
  );
}

function LoginForm({
  setAuthPageType,
  commonError,
  isLoading,
  setCommonError,
}: Props & {
  commonError: string;
  setCommonError: (value: string) => void;
  isLoading: boolean;
}) {
  const { isSubmitting } = useFormikContext<LoginFormValues>();

  const [isSeen, setIsSeen] = useState(false);
  const { t } = useTranslation();

  return (
    <Form noValidate autoComplete={'off'}>
      <S.FormContent>
        {/*{(isSubmitting || isLoading) && (*/}
        {/*  <Overlay>*/}
        {/*    <Spinner color="green" />*/}
        {/*  </Overlay>*/}
        {/*)}*/}

        <S.InputBlock>
          <S.InputRow>
            <S.Label>
              {t('login')}/{t('mail')}
            </S.Label>
            <TextInputFormik error={'commonError'} name="login" type="login" />
          </S.InputRow>
          <S.InputRow>
            <S.Label>{t('password')}</S.Label>
            <TextInputFormik
              name="password"
              type={isSeen ? 'text' : 'password'}
            />
            <S.EyeBlock
              type={'button'}
              isSeen={isSeen}
              onClick={() => setIsSeen(!isSeen)}
            >
              <EyeCloseSvg />
              <EyeSvg />
            </S.EyeBlock>
          </S.InputRow>
        </S.InputBlock>
        {commonError ? (
          <S.FormErrorLabel>{commonError}</S.FormErrorLabel>
        ) : null}
        <S.SignInButtonRow>
          <S.SignInButton
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            isSubmitting={isSubmitting}
            onClick={() => setCommonError('')}
          >
            {t('logIn')}
          </S.SignInButton>
          <S.ForgotPasswordButton
            type="button"
            onClick={() => setAuthPageType('password_recovery')}
          >
            {t('restorePassword')}
          </S.ForgotPasswordButton>
        </S.SignInButtonRow>
      </S.FormContent>
    </Form>
  );
}

export default LoginFormContainer;
