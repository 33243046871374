import React from 'react';
import styled from 'styled-components';
import {LinkProps} from 'next/link';

import {
  createLinkComponent,
  LinkToPropType,
  TagerLinkProps,
} from '@tager/web-components';

import {
  isAbsoluteUrl,
  isNonNullObjectGuard,
  isStringGuard,
  Nullish,
} from '@tager/web-core';

import {getSubpathByLang} from '@/utils/common';
import {useTranslation} from '@/components/I18nProvider';

const NextLink = createLinkComponent();

export type SimplifiedLinkToType = Nullish<string | { href: LinkProps['href']; as: string }>;

type CustomTagerLinkProps = Omit<TagerLinkProps, 'to'> & {
  to: SimplifiedLinkToType;
};

function Link(props: CustomTagerLinkProps) {
  const {language} = useTranslation();

  /** Handle external link */
  const href = isNonNullObjectGuard(props.to) ? String(props.to.as) : props.to;
  const isExternalLink = isAbsoluteUrl(href ?? '');

  if (isExternalLink) {
    return <DefaultLink {...props} href={href}/>;
  }

  /** Handle internal link */

  const {to, ...rest} = props;

  let updatedTo: LinkToPropType = to;

  const subpath = getSubpathByLang(language);

  /** Handle string links, if current language has subpath */
  if (isStringGuard(updatedTo) && subpath && updatedTo.startsWith(subpath)) {
    const urlWithoutSubpath = updatedTo.slice(subpath.length) || '/';

    updatedTo = {
      href: urlWithoutSubpath,
      as: updatedTo,
    };
  }

  return <NextLink to={updatedTo} {...rest} />;
}

const DefaultLink = styled.a``;

export default Link;
