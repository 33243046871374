import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { useOnClickOutside } from '@tager/web-core';

import { ReactComponent as LanguageRusSvg } from '@/assets/svg/rus.svg';
import { ReactComponent as LanguageEnSvg } from '@/assets/svg/en.svg';
import { useTranslation } from '@/components/I18nProvider';

import LanguageSelectPopup from './LanguageSelectPopup';

function LanguageMobileSelect() {
  const { language } = useTranslation();
  const [opened, setOpened] = useState(false);

  const selectRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(selectRef, () => setOpened(false));

  return (
    <Wrapper>
      <Component onClick={() => setOpened(true)}>
        <Left>{language === 'ru' ? 'Язык: Русский' : 'Language: English'}</Left>
        <Right>
          {language === 'ru' ? <LanguageRusSvg /> : <LanguageEnSvg />}
        </Right>
      </Component>
      {opened && (
        <Select ref={selectRef}>
          <LanguageSelectPopup onClose={() => setOpened(false)} />
        </Select>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
`;

const Component = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  line-height: 20px;
  padding: 10px 20px 10px 30px;

  &:hover {
    background: #f3f5f6;
  }
`;

const Left = styled.div`
  color: var(--language-current-color);
`;

const Right = styled.div`
  svg {
    height: 20px;
    fill: var(--main-text-color);
  }
`;

const Select = styled.div`
  background: var(--main-bg-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
`;

export default LanguageMobileSelect;
