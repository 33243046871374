import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from '@components/I18nProvider';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { PostShort } from '@/typings/model';
import Link from '@/components/Link';
import { getPostDate, getPostUrl } from '@/utils/posts';
import Picture from '@/components/Picture';
import PostMeta from '@/components/PostMeta/PostMeta';
import Bookmark from '@/components/Bookmark';

type Props = {
  posts: Array<PostShort>;
  onClose: () => void;
};

function SearchPostList({ posts, onClose }: Props) {
  const { t } = useTranslation();

  return (
    <List>
      {posts.map((post) => (
        <Row key={post.id}>
          <Post>
            {post.coverImage ? (
              <Left onClick={onClose}>
                <Link to={getPostUrl(post)}>
                  <Picture
                    {...convertThumbnailToPictureProps(
                      post.coverImage.previewSearch
                    )}
                  />
                </Link>
              </Left>
            ) : null}
            <Right>
              <TitleBlock onClick={() => onClose()}>
                <Link
                  as={Title}
                  to={getPostUrl(post)}
                  onClick={() => {
                    onClose();
                  }}
                >
                  {post.title}
                </Link>
              </TitleBlock>
              <Excerpt>{post.excerpt}</Excerpt>
              <Footer>
                <Bookmark post={post} />
                <PostMeta
                  date={getPostDate(post.date, t)}
                  category={
                    post.categories && post.categories.length
                      ? post.categories[0].name
                      : null
                  }
                />
              </Footer>
            </Right>
          </Post>
        </Row>
      ))}
    </List>
  );
}

const List = styled.ul`
  margin-bottom: 75px;
`;

const Row = styled.li`
  margin-top: 75px;
  &:first-child {
    margin-top: 0;
  }

  ${media.tabletSmallOnly(css`
    margin-top: 50px;
  `)}

  ${media.mobile(css`
    margin-top: 35px;
    padding-bottom: 14px;
    border-bottom: solid 0.01px;
  `)}
`;
const Post = styled.div`
  display: flex;
  ${media.mobile(css`
    display: block;
  `)}
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1px;
  overflow: hidden;
  ${media.mobile(css`
    margin-top: 9px;
  `)}
`;
const TitleBlock = styled.div`
  display: inherit;
`;
const Footer = styled.div`
  margin-top: auto;
  display: flex;
`;

const Title = styled.a`
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 5px;
  max-width: 600px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    transition: all 200ms ease;
    max-width: 100%;
    color: #ed4040;
  }

  ${media.tabletSmallOnly(css`
    font-size: 24px;
    line-height: 28px;
  `)}

  ${media.mobile(css`
    font-size: 20px;
    line-height: 23px;
    overflow-x: scroll;
    text-overflow: unset;
  `)}
`;

const Excerpt = styled.p`
  max-width: 600px;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 8px;
  ${media.mobile(css`
    font-size: 15px;
    line-height: 22px;
  `)}
`;

const Left = styled.div`
  margin: 4px 30px 0 0;
  height: auto;
  min-height: 135px;
  &:hover + ${Right} ${Title} {
    transition: all 200ms ease;
    max-width: 100%;
    color: #ed4040;
  }
  ${media.mobile(css`
    margin: 4px 0 10px 0;
    img {
      margin: 0 auto;
    }
  `)}
`;

export default SearchPostList;
