import React from 'react';
import styled, { css } from 'styled-components';

import { isAbsoluteUrl } from '@tager/web-core';

import Link from '@/components/Link';
import { ReactComponent as LinkArrowSvg } from '@/assets/svg/Linkarrow.svg';
import { ReactComponent as YoutubeSvg } from '@/assets/svg/youtube.svg';
import { ReactComponent as FacebookSvg } from '@/assets/svg/facebook.svg';
import { ReactComponent as VkSvg } from '@/assets/svg/vk.svg';
import { ReactComponent as TwitterSvg } from '@/assets/svg/twitter.svg';
import { media } from '@/utils/mixin';

type Props = {
  to: string | null;
  label: string;
  isNewTab: boolean;
};

const isYoutubeUrl = (url: string): boolean => {
  return (
    url.startsWith('https://youtube.com') ||
    url.startsWith('https://www.youtube.com')
  );
};

const isFacebookUrl = (url: string): boolean => {
  return (
    url.startsWith('https://facebook.com') ||
    url.startsWith('https://www.facebook.com')
  );
};

const isVkUrl = (url: string): boolean => {
  return (
    url.startsWith('https://vk.com') ||
    url.startsWith('https://www.vk.com') ||
    url.startsWith('http://www.vk.com') ||
    url.startsWith('http://vk.com')
  );
};

const isTwitterUrl = (url: string): boolean => {
  return (
    url.startsWith('https://twitter.com') ||
    url.startsWith('https://www.twitter.com')
  );
};

const isSocialUrl = (url: string | null): boolean => {
  return getSocialIcon(url) !== null;
};

const getSocialIcon = (url: string | null) => {
  if (!isAbsoluteUrl(url ?? '')) {
    return null;
  }

  if (!url) {
    return null;
  }

  if (isVkUrl(url)) {
    return <VkSvg />;
  }

  if (isYoutubeUrl(url)) {
    return <YoutubeSvg />;
  }

  if (isFacebookUrl(url)) {
    return <FacebookSvg />;
  }

  if (isTwitterUrl(url)) {
    return <TwitterSvg />;
  }

  return null;
};

const getHoverColor = (url: string | null): string => {
  if (!url) {
    return '#000';
  }

  if (isYoutubeUrl(url)) {
    return '#e91d00';
  }

  if (isVkUrl(url)) {
    return '#4f719b';
  }

  if (isTwitterUrl(url)) {
    return '#059ff5';
  }

  if (isFacebookUrl(url)) {
    return '#39579a';
  }

  return '#000';
};

function FooterMenuLink({ to, label, isNewTab }: Props) {
  const socialIcon = getSocialIcon(to);

  const isExternalLink = isAbsoluteUrl(to ?? '');
  return (
    <MenuLink
      to={to}
      target={isNewTab ? '_blank' : ''}
      hoverColor={getHoverColor(to)}
      hoverUnderline={!isSocialUrl(to)}
    >
      {socialIcon && <Icon>{socialIcon}</Icon>}
      {label}
      {isExternalLink && !isSocialUrl(to) && (
        <Blank>
          <LinkArrowSvg />
        </Blank>
      )}
    </MenuLink>
  );
}

const Blank = styled.div`
  margin-left: 5px;
  svg {
    width: 6px;
    height: 6px;
    fill: var(--main-text-color);
  }
`;

const Icon = styled.div`
  margin-right: 7px;
  svg {
    width: 20px;
    height: 14px;
    display: block;
    opacity: 0.4;
    fill: var(--main-text-color);
  }
`;

const MenuLink = styled(Link)<{ hoverColor: string; hoverUnderline: boolean }>`
  display: flex;
  align-items: center;
  opacity: 0.6;
  ${media.mobileLarge(css`
    opacity: 1;
  `)}
  &:hover {
    text-decoration: ${(props) =>
      props.hoverUnderline ? 'underline' : 'none'};
    color: ${(props) => props.hoverColor};
    opacity: 1;
    ${Icon} {
      svg {
        opacity: 1;
        fill: ${(props) => props.hoverColor};
      }
    }
  }
`;

export default FooterMenuLink;
