import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  useFormikContext,
} from 'formik';
import { useRouter } from 'next/router';
import { TextInputFormik } from '@components/TextInput';
import { registration, sendRegisterLink } from '@services/requests';
import { useDispatch } from 'react-redux';
import { useTranslation } from '@components/I18nProvider';

import {
  convertRequestErrorToMap,
  redirect,
  validators,
} from '@tager/web-core';

import { AuthPageType } from '@/components/modals/AuthModal/AuthModal.types';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
// import { signInThunk } from '@/store/reducers/auth';

import { ReactComponent as EyeCloseSvg } from '@/assets/svg/eyeClose.svg';
import { ReactComponent as EyeSvg } from '@/assets/svg/eye.svg';
import { signInThunk } from '@/store/reducers/auth';
import { getErrorLabel } from '@/typings/common';
import { StoreDispatch } from '@/store/store';

import * as S from '../AuthModal.style';
type RegistrationFormValues = {
  login: string;
  password: string;
};

const INITIAL_VALUES: RegistrationFormValues = {
  login: '',
  password: '',
};

function validate(
  values: RegistrationFormValues
): FormikErrors<RegistrationFormValues> {
  const errors: FormikErrors<RegistrationFormValues> = {};

  if (validators.required(values.login)) {
    errors.login = 'emptyLogin';
  }
  if (validators.required(values.password)) {
    errors.password = 'emptyPassword';
  }

  return errors;
}

type Props = {
  setAuthPageType: (value: AuthPageType) => void;
  closeModal: () => void;
  uuid: string;
};

function RegistrationSubmitPage(props: Props) {
  const [commonError, setCommonError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<StoreDispatch>();

  const { closeModal } = props;

  function handleSubmit(
    values: RegistrationFormValues,
    formikHelpers: FormikHelpers<RegistrationFormValues>
  ) {
    setCommonError('');

    registration(values.login, values.password, props.uuid)
      .then(() => {
        dispatch(signInThunk(values))
          .then(() => closeModal())
          .finally(() => formikHelpers.setSubmitting(false));
      })
      .catch((error) => {
        const textError = getErrorLabel(error.body.message);

        setCommonError(textError);
        formikHelpers.setSubmitting(false);
      });
  }
  return (
    <Formik<RegistrationFormValues>
      initialValues={INITIAL_VALUES}
      onSubmit={handleSubmit}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <RegistrationForm
        {...props}
        isLoading={isLoading}
        commonError={commonError}
        setCommonError={() => setCommonError('')}
      />
    </Formik>
  );
}

function RegistrationForm({
  commonError,
  setCommonError,
}: Props & {
  commonError: any;
  setCommonError: (value: string) => void;
  isLoading: boolean;
}) {
  const { isSubmitting } = useFormikContext<RegistrationFormValues>();
  const [isSeen, setIsSeen] = useState(false);
  const { t } = useTranslation();

  return (
    <Form noValidate autoComplete={'off'}>
      <S.FormContent>
        <S.InputBlock>
          <S.InputRow>
            <S.Label> {t('login')}</S.Label>
            <TextInputFormik name="login" type="login" />
          </S.InputRow>
          <S.InputRow>
            <S.Label>{t('password')}</S.Label>
            <TextInputFormik
              name="password"
              type={isSeen ? 'text' : 'password'}
            />
            <S.EyeBlock
              type={'button'}
              isSeen={isSeen}
              onClick={() => setIsSeen(!isSeen)}
            >
              <EyeCloseSvg />
              <EyeSvg />
            </S.EyeBlock>
          </S.InputRow>
        </S.InputBlock>
        {commonError ? (
          <S.FormErrorLabel>{t(commonError)}</S.FormErrorLabel>
        ) : null}

        <S.SignInButtonRow>
          <S.SignInButton
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            isSubmitting={isSubmitting}
            onClick={() => setCommonError('')}
          >
            {t('signUpButton')}
          </S.SignInButton>
        </S.SignInButtonRow>
      </S.FormContent>
    </Form>
  );
}

export default RegistrationSubmitPage;
