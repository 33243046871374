import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '@components/I18nProvider';

import { Nullable } from '@tager/web-core';

type Props = {
  date: Nullable<string>;
  category: Nullable<string>;
};

function PostMeta({ date, category }: Props) {
  if (!category && !date) {
    return null;
  }
  return (
    <Component>
      {date && (
        <Cell>
          <Item>{date}</Item>
        </Cell>
      )}
      {category && (
        <Cell>
          <Item>{category}</Item>
        </Cell>
      )}
    </Component>
  );
}

const Component = styled.div`
  display: flex;
`;

const Cell = styled.div`
  padding-right: 10px;
  margin-right: 10px;
  position: relative;

  &:last-child {
    margin-right: 0;
    padding-right: 0;
  }

  &:first-child {
    &:after {
      content: '';
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: #999;
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const Item = styled.span`
  color: #999;
  font-size: 14px;
  white-space: nowrap;
`;

export default PostMeta;
