import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { cookie } from '@tager/web-core';

import urlThemeNight from '@/assets/images/png/theme-night.png';
import urlThemeDay from '@/assets/images/png/theme-day.png';
import { useTranslation } from '@/components/I18nProvider';

function ThemeSelect() {
  const [theme, setTheme] = useState<'dark' | 'light'>(
    cookie.get('theme') === 'dark' ? 'dark' : 'light'
  );
  const { t } = useTranslation();
  const themeToggle = () => {
    if (theme === 'light') {
      setTheme('dark');
      document.documentElement.setAttribute('data-theme', 'dark');
      cookie.set('theme', 'dark');
    } else {
      setTheme('light');
      document.documentElement.setAttribute('data-theme', 'light');
      cookie.set('theme', 'light');
    }
  };
  return (
    <Theme>
      <Btn onClick={() => themeToggle()} mode={theme} />
      {theme === 'dark' ? t('night') : t('day')}
    </Theme>
  );
}

const Theme = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0 0 31px;
`;

const Btn = styled.button<{ mode: 'dark' | 'light' }>`
  background: rgba(47, 142, 254, 0.35);
  box-shadow: 0 2px 8px rgba(32, 41, 63, 0.1);
  border-radius: 24px;
  height: 11px;
  width: 35px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;

  ${(props) =>
    props.mode === 'dark'
      ? css`
          &:before {
            content: '';
            background: url(${urlThemeNight});
            left: 15px;
          }
        `
      : css`
          &:before {
            content: '';
            background: url(${urlThemeDay});
            left: -4px;
          }
        `}

  &:before {
    transition: all 0.181s ease-in-out;
    width: 25px;
    height: 25px;
    position: absolute;
    transform: translateY(-56%);
  }
`;

export default ThemeSelect;
