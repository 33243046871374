import React from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import AuthModal from '@components/modals/AuthModal';

import { useModal } from '@components/Modal/Modal.hooks';
import { useTranslation } from '@components/I18nProvider';
import { MenuItemType } from '@tager/web-modules';
import { redirect } from '@tager/web-core';

import { ReactComponent as CloseIcon } from '@/assets/svg/header-dropdown-close.svg';
import DropdownMenu from '@/components/Layout/components/Header/components/DropdownMenu';
import LanguageMobileSelect from '@/components/Layout/components/Header/components/LanguageMobileSelect';
import ThemeSelect from '@/components/Layout/components/Header/components/ThemeSelect';
import { useTypedSelector } from '@/store/store';
import {
  selectIsUserAuthorized,
  selectUserProfile,
  signOutThunk,
} from '@/store/reducers/auth';

import Bookmarks from './Bookmars';

type Props = {
  menu: Array<MenuItemType>;
  onClose: () => void;
};

function MobileSidebar(props: Props) {
  const { menu, onClose } = props;
  const openModal = useModal();
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const isUserAuthorized = useTypedSelector((state) =>
    selectIsUserAuthorized(state)
  );

  const profile = useTypedSelector((state) => selectUserProfile(state));

  function handleLogin() {
    if (!isUserAuthorized) {
      openModal(AuthModal, {});
      onClose();
    }
  }
  function handleSignOut() {
    if (dispatch(signOutThunk())) {
      onClose();
      redirect({ currentUrl: router.asPath ?? '', location: '/' });
    }
  }

  return (
    <MobileDropDown>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <ThemeSelect />
      {isUserAuthorized ? (
        <>
          <Title>{t('personalAccount')}</Title>
          <HeaderBlock>
            {profile ? (
              <ProfilePageLink>
                {profile.firstName} {profile.lastName}
              </ProfilePageLink>
            ) : null}
            <Bookmarks onClose={onClose} />
          </HeaderBlock>
        </>
      ) : null}
      <Menu>
        <DropdownMenu menu={menu} />
      </Menu>
      <Bottom>
        <LanguageMobileSelect />
        <LoginSection>
          {!isUserAuthorized ? (
            <HeaderButton onClick={() => handleLogin()}>Войти</HeaderButton>
          ) : (
            <HeaderButton onClick={() => handleSignOut()}>Выйти</HeaderButton>
          )}
        </LoginSection>
      </Bottom>
    </MobileDropDown>
  );
}

const MobileDropDown = styled.div`
  background: var(--dropdown-bg-color);
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  bottom: 0;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
  border-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(0, 0, 0, 0.1)),
      to(transparent)
    )
    1 100%;
  border-image: linear-gradient(0deg, rgba(0, 0, 0, 0.1), transparent) 1 100%;
  width: 270px;
  border-width: 1px;
  border-style: solid;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  z-index: 111;

  &:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    background: var(--dropdown-bg-color);
    border-bottom: 1px solid var(--dropdown-border-color);
  }
`;

const CloseButton = styled.button`
  top: 16px;
  right: 13px;
  width: 24px;
  position: fixed;
  z-index: 112;
  svg {
    fill: var(--main-text-color);
  }
`;

const Title = styled.div`
  padding: 20px 0 0 30px;
  font-size: 13px;
  color: #999;
  letter-spacing: 1.73px;
  text-transform: uppercase;
  line-height: 14px;
  display: block;
`;

const HeaderBlock = styled.div`
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--dropdown-border-color);
`;

const ProfilePageLink = styled.div`
  font-size: 15px;
  padding: 0 30px;
  line-height: 30px;
  align-items: center;
  text-align: left;
  width: 100%;
  display: flex;
  transition: 0.2s;
  &:hover {
    background: var(--dropdown-bg-hover-color);
  }
`;

const Menu = styled.div`
  margin-top: 20px;
`;
const LoginSection = styled.div`
  padding: 15px 0;
`;

const HeaderButton = styled.button`
  background: var(--dropdown-bg-color);
  color: var(--main-text-color);
  font-size: 15px;
  padding: 4px 30px;
  margin: -7px 0;
  line-height: 30px;
  align-items: center;
  text-align: left;
  width: 100%;
  display: flex;
  transition: 0.2s;
  &:hover {
    background: var(--dropdown-bg-hover-color);
  }
`;

const Bottom = styled.div`
  border-top: 1px solid var(--dropdown-border-color);
  margin-top: 20px;
  padding-top: 10px;
`;

export default MobileSidebar;
