import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import FooterMenu from '@/components/Layout/components/Footer/components/FooterMenu';
import { ReactComponent as UtmediaLogo } from '@/assets/svg/logo.svg';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { useTranslation } from '@/components/I18nProvider';
import { withLang } from '@/utils/common';

function Footer() {
  const { language, t } = useTranslation();

  const footerMenuItemList = useTypedSelector((state) =>
    selectMenuItemListByAlias(state, withLang('footer_menu', language))
  );

  return (
    <FooterContainer>
      <FooterInner>
        <FooterLeft>
          <FooterLogoWrap>
            <UtmediaLogo />
          </FooterLogoWrap>
          <FooterLeftElem>
            © United Traders {new Date().getFullYear()}
          </FooterLeftElem>
          <FooterLeftElem>
            <a
              href="https://unitedtraders.com/personal/offer"
              target="_blank"
              rel="noreferrer"
            >
              {t('licenseAgreement')}
            </a>
          </FooterLeftElem>
        </FooterLeft>

        <FooterRight>
          {footerMenuItemList ? (
            <FooterMenuWrapper>
              {footerMenuItemList.map((menuItem) => (
                <FooterMenuCell key={menuItem.id}>
                  <FooterMenu menu={menuItem} />
                </FooterMenuCell>
              ))}
            </FooterMenuWrapper>
          ) : null}
        </FooterRight>
      </FooterInner>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer``;

const FooterMenuWrapper = styled.div`
  display: flex;
  ${media.mobile(css`
    display: block;
  `)}
`;

const FooterMenuCell = styled.div`
  margin-right: 60px;
  &:last-child {
    margin-right: 0;
  }
  ${media.mobile(css`
    margin-right: 0;
    margin-top: 35px;
    &:first-child {
      margin-top: 0;
    }
  `)}
`;

const FooterInner = styled.div`
  border-top: 1px solid #d8d8d8;
  margin-top: 40px;
  padding: 30px 0 50px;
  display: flex;
  justify-content: space-between;
  ${media.mobile(css`
    display: flex;
    padding: 40px 15px;
    flex-direction: column-reverse;
    margin: 0 -15px;
  `)}
`;
const FooterRight = styled.div`
  display: flex;
  ${media.mobileLarge(css`
    display: block;
  `)}
  ${media.mobile(css`
    margin-bottom: 50px;
  `)}
`;

const FooterLeft = styled.div`
  flex-basis: 50%;
`;

const FooterLogoWrap = styled.span`
  display: block;
  width: 170px;
  height: 50px;
  opacity: 0.3;
  margin-bottom: 20px;
  svg {
    width: 170px;
    height: 25px;
    fill: var(--main-text-color);
  }
`;
const FooterLeftElem = styled.p`
  margin-bottom: 20px;
  font-size: 15px;
  color: var(--main-text-color);
  opacity: 0.6;
  ${media.mobileLarge(css`
    opacity: 1;
  `)}
  a:before {
    content: '';
    position: absolute;
    bottom: -2px;
    left: -2px;
    right: -1px;
    height: 1px;
    background: var(--main-text-color);
  }
  a:hover {
    opacity: 0.75;
  }
  a {
    display: inline-block;
    position: relative;
    background-color: transparent;
    color: inherit;
    text-decoration: none;
    transition: 0.3s all ease;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export default Footer;
