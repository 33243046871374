import React from 'react';
import styled from 'styled-components';

import { generateNumberArray } from '@tager/web-core';

import PlaceholderForSearchList from './components/PlaceholderForSearchList';

function PlaceholderSearchList() {
  return (
    <List>
      {generateNumberArray(5).map((num) => (
        <PlaceholderForSearchList key={num} />
      ))}
    </List>
  );
}

const List = styled.ul``;

export default PlaceholderSearchList;
