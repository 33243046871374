import { useRouter } from 'next/router';
import { useMemo } from 'react';

import {
  getAliasWithoutId,
  getParamAsString,
  getSubpathByLang,
} from '@/utils/common';
import { useTypedSelector } from '@/store/store';
import { selectCategoryByAliasAndLang } from '@/store/reducers/pages/category';
import { selectPost } from '@/store/reducers/pages/post';
import { useTranslation } from '@/components/I18nProvider';

export function useActivePath(): string {
  const router = useRouter();
  const { language } = useTranslation();
  const alias = getParamAsString(router.query.alias);
  const category = useTypedSelector((state) =>
    selectCategoryByAliasAndLang(state, alias, language)
  );
  const post = useTypedSelector((state) =>
    selectPost(state, getAliasWithoutId(alias))
  );

  const categoryAlias = useMemo(() => {
    if (category) return category.urlAlias;
    if (post) return post.categories[0]?.urlAlias ?? null;
    return null;
  }, [category, post]);

  const isPostOrCategoryPage =
    router.asPath.includes('/posts/') || router.asPath.includes('/category/');

  const prefix = getSubpathByLang(language);

  if (isPostOrCategoryPage) return `${prefix}/category/${categoryAlias}`;

  return router.asPath;
}
