import React from 'react';
import styled, { css } from 'styled-components';

import { MenuItemType } from '@tager/web-modules';
import { CustomLinkProps } from '@tager/web-components';

import { scroller } from '@tager/web-core';
import { media } from '@/utils/mixin';
import Link from '@/components/Link';

import { useActivePath } from './MainMenu.hooks';

type Props = {
  menuList: Array<MenuItemType>;
};

function MainMenu({ menuList }: Props) {
  const activePath = useActivePath();

  return (
    <Nav>
      <NavList>
        {menuList.map((menuItem) => (
          <NavItem onClick={() => scroller.unlock()} key={menuItem.id}>
            <Link
              as={NavLink}
              to={menuItem.link}
              isActive={menuItem.link === activePath}
            >
              {menuItem.label}
            </Link>
          </NavItem>
        ))}
      </NavList>
    </Nav>
  );
}

const Nav = styled.nav``;

const NavList = styled.ul`
  max-width: 600px;
  margin: 0;
  display: flex;
  justify-content: flex-start;
`;

const NavItem = styled.li`
  margin: 0 8px 0 9px;
  transition: 0.5s;
  &:hover {
    opacity: 0.6;
  }
  ${media.tabletSmall(css`
    &:first-child {
      margin-left: 0;
    }
  `)}
  ${media.mobile(css`
    &:last-child {
      padding-right: 10px;
    }
  `)}
`;
const NavLink = styled.a<CustomLinkProps>`
  font-size: 15px;
  line-height: 30px;
  border-bottom: 2px solid transparent;
  display: inline-block;
  white-space: nowrap;

  ${(props) =>
    props.isActive &&
    css`
      color: var(--blue-color);
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -3px;
        background: var(--blue-color);
        height: 2px;
      }
    `}

  ${media.tabletLarge(css`
    font-size: 14px;
  `)}
`;

export default MainMenu;
