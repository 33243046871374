import { TFunction } from 'next-i18next';

import { PostShort } from '@/typings/model';
import { getSubpathByLang } from '@/utils/common';

export function getPostUrl(post: PostShort): string {
  const prefix = getSubpathByLang(post.language);
  return `${prefix}/posts/${post.id}-${post.urlAlias}`;
}
export function shuffleArray(array: Array<PostShort>) {
  if (array.length <= 3) return array;

  return array
    .map((a) => ({ sort: Math.random(), value: a }))
    .sort((a, b) => a.sort - b.sort)
    .map((a) => a.value);
}

export function getPostDate(post: string, t: TFunction): string {
  const date1: Date = new Date();
  const date2: Date = new Date(post);
  const diffDate: number = Math.abs(
    new Date(date1.toISOString().slice(0, 10)).getTime() - date2.getTime()
  );
  function getDay(date: number): number {
    return Math.ceil(date / (1000 * 3600 * 24));
  }
  function getWeek(date: number): number {
    return Math.ceil(date / (1000 * 3600 * 24 * 7));
  }
  function getMonth(date: number): number {
    return Math.ceil(date / (1000 * 3600 * 24 * 31));
  }

  if (getDay(diffDate) <= 7) {
    const dateString = String(getDay(diffDate));
    if (getDay(diffDate) === 0) {
      return `${t('today')}`;
    }
    if (getDay(diffDate) === 1) {
      return `${dateString} ${t('dayAgo')}`;
    }
    if (getDay(diffDate) <= 4) {
      return `${dateString} ${t('secondDayAgo')}`;
    }
    return `${dateString} ${t('daysAgo')}`;
  }

  if (getWeek(diffDate) <= 4) {
    const dateString = String(getWeek(diffDate));
    if (getWeek(diffDate) === 1) {
      return `${dateString} ${t('weekAgo')}`;
    }
    if (getWeek(diffDate) <= 4) {
      return `${dateString} ${t('secondWeeksAgo')}`;
    }
    return `${dateString} ${t('weeksAgo')}`;
  }
  const dateString = String(getMonth(diffDate));
  if ([1, 21, 31, 41, 51, 61, 71, 81, 91, 101].includes(getMonth(diffDate))) {
    return `${dateString} ${t('monthAgo')}`;
  }
  if (getMonth(diffDate) <= 4) {
    return `${dateString} ${t('secondMonthsAgo')}`;
  }
  return `${dateString} ${t('monthsAgo')}`;
}
