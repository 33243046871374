import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from '@components/I18nProvider';

import { FETCH_STATUSES, useDebounce } from '@tager/web-core';

import { ReactComponent as SearchClose } from '@/assets/svg/searchClose.svg';
import { media } from '@/utils/mixin';
import {
  searchThunk,
  selectFindPostListResource,
} from '@/store/reducers/pages/search';
import { useTypedSelector } from '@/store/store';

import PlaceholderSearchList from './PlaceholderSearchList';
import Logo from './Logo';
import SearchPostList from './SearchPostList';

type Props = {
  onClose: () => void;
};

function SearchScreen({ onClose }: Props) {
  const foundPostListResource = useTypedSelector(selectFindPostListResource);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce(searchQuery, 300);
  const { t, language } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(searchThunk(language, debouncedSearchQuery));
  }, [debouncedSearchQuery, dispatch]);

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  }, []);

  return (
    <SearchScreenPage>
      <SearchContainer>
        <SearchScreenInner>
          <SearchScreenHeader>
            <Logo />
            <SearchScreenClose>
              <SearchClose
                onClick={() => {
                  onClose();
                }}
              />
            </SearchScreenClose>
          </SearchScreenHeader>
          <SearchInputWrap>
            <SearchInput
              ref={inputRef}
              placeholder="Поиск"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </SearchInputWrap>
          <SearchResult>
            {foundPostListResource.status === FETCH_STATUSES.LOADING ? (
              <PlaceholderSearchList />
            ) : (
              <SearchPostList
                onClose={onClose}
                posts={foundPostListResource.data}
              />
            )}
          </SearchResult>
        </SearchScreenInner>
      </SearchContainer>
    </SearchScreenPage>
  );
}

const SearchScreenPage = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: var(--main-bg-color);
  overflow: auto;
  opacity: 0;
  animation: show 0.7s;
  animation-fill-mode: forwards;
  padding: 10px 0;
  ${media.tablet(css`
    padding: 11px 0;
  `)}
  ${media.mobile(css`
    padding: 0 0;
  `)}
  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
const SearchScreenInner = styled.div`
  position: relative;
`;

const SearchScreenHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 21px;
  ${media.tablet(css`
    padding-top: 0;
  `)}
  ${media.mobile(css`
    padding-top: 10px;
  `)}
`;

const SearchContainer = styled.div`
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 3px;
  ${media.tablet(css`
    max-width: 1000px;
  `)}
  ${media.mobile(css`
    padding: 0 16px;
  `)}
  ${media.tabletSmallOnly(css`
    padding: 0 9px;
  `)}
  
`;

const SearchScreenClose = styled.button`
  margin-right: 30px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  width: 24px;
  height: 24px;
  margin-top: 2px;
  ${media.mobileLarge(css`
    margin-right: 0;
  `)}
  svg {
    width: 24px;
    height: 24px;
    fill: var(--main-text-color);
  }
`;
const SearchInputWrap = styled.div`
  padding-top: 44px;
  ${media.mobileLarge(css`
    padding-top: 33px;
  `)}
`;
const SearchInput = styled.input`
  display: block;
  border: 0;
  background: var(--main-bg-color);
  border-bottom: 2px solid var(--main-text-color);
  width: 100%;
  font-size: 42px;
  line-height: 62px;
  letter-spacing: 0.8px;
  color: var(--main-text-color);
  &::placeholder {
    opacity: 0.4;
  }
  ${media.mobileLarge(css`
    font-size: 32px;
    line-height: 37px;
  `)}
`;
const SearchResult = styled.div`
  padding-top: 55px;
`;

export default SearchScreen;
