import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

function getBorderColor(
  props: { invalid?: boolean; active?: boolean } = {}
): string {
  return props.invalid ? colors.red : props.active ? 'black' : '#D3C8C4';
}

export const Input = styled.input<{
  withPrefix?: boolean;
  invalid?: boolean;
  isPassword?: boolean;
}>`
  padding-left: 117px;
  padding-right: ${(props) => (props.isPassword ? 47 : 15)}px;
  height: 99%;
  width: 100%;
  border: 0;
  background-color: initial;
  color: var(--form-input-color);
  line-height: 100%;
  ${(props) =>
    props.withPrefix
      ? css`
          padding-left: 45px;
        `
      : ''};

  &::placeholder {
    color: ${(props) => (props.invalid ? colors.red : '#85726b')};
    opacity: 0.8;
  }

  &:focus {
    border-color: ${({ invalid, readOnly }) =>
      getBorderColor({ invalid, active: !readOnly })};
  }
`;

export const prefixCss = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  pointer-events: none;
`;

export const Prefix = styled.svg`
  ${prefixCss};
`;
