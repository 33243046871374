import React from 'react';
import styled, { css } from 'styled-components';

import { useTranslation } from '@components/I18nProvider';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { TranslationKey } from '@/constants/locales';

type Props = {
  className?: string;
  error?: any;
  children: React.ReactNode;
};

function FormControl({ className, error, children }: Props) {
  const { t } = useTranslation();

  return (
    <Control className={className} isError={!!error}>
      {children}
      {error ? <ErrorMessage>{t(error)}</ErrorMessage> : null}
    </Control>
  );
}

const ErrorMessage = styled.span`
  position: absolute;
  left: 100%;
  margin-left: 15px;
  background: #fff4e9;
  top: 0;
  bottom: 0;
  padding: 0 15px;
  width: 287px;
  color: #ff7e00;
  border: 1px solid #ffdfc0;
  line-height: 18px;
  font-size: 13px;
  display: flex;
  align-items: center;
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #ffdfc0;
    left: -10px;
  }
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-right: 9px solid #fff4e9;
    left: -9px;
  }
  ${media.mobile(css`
    left: 0;
    top: initial;
    bottom: 0;
    margin-left: 0;
    width: 100%;
    height: 50%;
    &::after,
    ::before {
      content: none;
    }
  `)}
`;

const Control = styled.div<{ isError: boolean }>`
  transition: 0.5s;
  ${(props) =>
    props.isError
      ? css`
          ${media.mobile(css`
            padding-bottom: 50px;
          `)}
        `
      : css`
          ${media.mobile(css`
            padding-bottom: 0;
          `)}
        `}
`;

export default FormControl;
