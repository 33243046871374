import React from 'react';
import styled, { css } from 'styled-components';

import { Preloader } from '@tager/web-components';

import { media } from '@/utils/mixin';

import Header from './components/Header/Header';
import Footer from './components/Footer';

type Props = {
  children?: React.ReactNode;
  isPreloaderHidden?: boolean;
};

function Layout({ children, isPreloaderHidden }: Props) {
  return (
    <Container>
      <Preloader hidden={isPreloaderHidden} />
      <Header />
      <Main>{children}</Main>
      <Footer />
    </Container>
  );
}

const Container = styled.div`
  background-color: var(--main-bg-color);
  min-height: 100vh;
  margin: 0 auto;
  width: 100%;
  max-width: 1220px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  ${media.tabletLarge(css`
    //max-width: 1000px;
    padding: 0 30px;
    margin: 0 auto;
  `)}
  ${media.tabletSmall(css`
    padding: 0 20px;
  `)}
  ${media.mobile(css`
    padding: 0 16px;
  `)}
`;

const Main = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 200px;
`;

export default Layout;
