import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { useDispatch } from 'react-redux';
import { addUserBookmarks, removeUserBookmarks } from '@services/requests';
import AuthModal from '@components/modals/AuthModal';
import { useModal } from '@components/Modal/Modal.hooks';
import { useDebounce } from '@tager/web-core';

import { ReactComponent as BookmarksActiveSvg } from '@/assets/svg/bookmarksActive.svg';
import { ReactComponent as BookmarksSvg } from '@/assets/svg/bookmarks.svg';
import { PostShort } from '@/typings/model';
import { checkUserBookmarksThunk } from '@/store/reducers/pages/bookmarks';
import { StoreDispatch, useTypedSelector } from '@/store/store';
import { selectIsUserAuthorized } from '@/store/reducers/auth';

type Props = {
  post: PostShort;
};

function Bookmark({ post }: Props) {
  const [isActive, setActive] = useState<boolean>(false);
  const dispatch = useDispatch<StoreDispatch>();
  const openModal = useModal();

  const bookmark = useMemo(() => ({ id: post.id, alias: post.urlAlias }), [
    post.id,
    post.urlAlias,
  ]);
  const isUserAuthorized = useTypedSelector((state) =>
    selectIsUserAuthorized(state)
  );
  useEffect(() => {
    if (isUserAuthorized) {
      dispatch(checkUserBookmarksThunk(bookmark)).then((includesCurrentPost) =>
        setActive(includesCurrentPost)
      );
    }
  }, [bookmark, isUserAuthorized]);

  function toggleBookmark() {
    if (isUserAuthorized) {
      dispatch(checkUserBookmarksThunk(bookmark)).then(
        (includesCurrentPost) => {
          if (includesCurrentPost) {
            removeUserBookmarks(bookmark.id).then((newIsActive) =>
              setActive(false)
            );
          } else {
            addUserBookmarks(bookmark.id).then((newIsActive) =>
              setActive(newIsActive.success)
            );
          }
        }
      );
    } else {
      openModal(AuthModal, {});
    }
  }

  return (
    <BookmarkBlock isActive={isActive} onClick={toggleBookmark}>
      {isActive ? <BookmarksActiveSvg /> : <BookmarksSvg />}
    </BookmarkBlock>
  );
}

const BookmarkBlock = styled.button<{ isActive: boolean }>`
  margin-right: 10px;
  height: 18px;
  svg {
    width: 14px;
    height: 18px;
    white-space: nowrap;
    font-size: 15px;
    position: relative;
    transition: 0.2s ease all;
    fill: var(--main-text-color);
    opacity: 0.4;

    ${(props) =>
      props.isActive
        ? css`
            opacity: 1;
            fill: var(--blue-color);
          `
        : null}
    &:hover {
      opacity: 0.6;
    }
  }
`;

export default Bookmark;
